import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import api from "./api";

function LoginForm({ onLogin }) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState(""); // State for error messages
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    setErrorMessage(""); // Clear previous error messages
    api
      .post("/auth/login", { email: username, password })
      .then((response) => {
        console.log(response)
        if (response?.data?.token) {
          onLogin(response?.data?.user, response?.data?.token);
          sessionStorage.setItem("token", response.data.token);
          navigate("/dashboard");
        }else if (response?.data?.statusCode === 500) {
          setErrorMessage(response.data.message);
        } else {
          setErrorMessage("An unexpected error occurred. Please try again.");
        }
      })
      .catch((error) => {
        console.error("Login error:", error);
        // Check if statusCode is 500 and show the error message
      });
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  return (
    <>
      <section className="ftco-section" id="services-section">
        <div className="container">
          <div className="row">
            <form onSubmit={handleSubmit} className="bg-light p-4 p-md-5 login-form mx-auto">
              <h2 className="mb-3">Sign In</h2>
              {errorMessage && ( // Conditionally render the error message
                <div className="alert alert-danger" role="alert">
                  {errorMessage}
                </div>
              )}
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <label htmlFor="username">Username:</label>
                    <input
                      type="text"
                      name="username"
                      className="form-control"
                      placeholder="User Name"
                      id="username"
                      required
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group position-relative">
                    <label htmlFor="password">Password:</label>
                    <input
                      type={passwordVisible ? 'text' : 'password'}
                      className="form-control"
                      placeholder="***********"
                      id="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <button
                      type="button"
                      className="btn position-absolute end-0 top-50 translate-middle-y"
                      onClick={togglePasswordVisibility}
                      aria-label={passwordVisible ? 'Hide password' : 'Show password'}
                    >
                      <i className={`fa ${passwordVisible ? 'fa-eye-slash' : 'fa-eye'}`}></i>
                    </button>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <div id="user-input" className="inline">
                      <div className="form-group">
                        <div
                          className="g-recaptcha"
                          data-sitekey="6LfKURIUAAAAAO50vlwWZkyK_G2ywqE52NU7YO0S"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row align-items-center">
                <div className="col-md-6 col-12">
                  <div className="form-group">
                    <input
                      type="submit"
                      value="Login"
                      className="btn btn-primary py-3 px-5 w-md-75"
                    />
                  </div>
                </div>
                <div className="col-md-6 col-12">
                  <p className="text-md-right text-center">
                    <Link to="/forgot-password">Forgot Password</Link>
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <p className="text-center">
                    New Customer! <Link to="/register">Sign Up</Link> Now
                  </p>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
}

export default LoginForm;
