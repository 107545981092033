import React, { useState, useEffect } from "react";
import api from '../components/api';

function AdminQuery() {
  const [queries, setQueries] = useState([]);
  const [answers, setAnswers] = useState({});
  const [selectedQuery, setSelectedQuery] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const queriesPerPage = 15;

  useEffect(() => {
    const fetchQueriesData = async () => {
      try {
        const token = sessionStorage.getItem("token");
        const response = await api.get(`/admin/allQueries`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setQueries(response.data.allQueries);
        const initialAnswers = response.data.allQueries.reduce((acc, query) => {
          acc[query._id] = query.answer || "No";
          return acc;
        }, {});
        setAnswers(initialAnswers);
      } catch (error) {
        console.error("Error fetching query data:", error);
      }
    };

    fetchQueriesData();
  }, []);

  const handleAnswerChange = async (queryId, value) => {
    try {
      setAnswers({
        ...answers,
        [queryId]: value,
      });

      const token = sessionStorage.getItem("token");
      await api.put(`/admin/update-query/${queryId}`, 
        { answer: value },
        { headers: { Authorization: `Bearer ${token}` } }
      );
    } catch (error) {
      console.error("Error updating answer:", error);
    }
  };

  const handleViewQuery = (query) => {
    setSelectedQuery(query);
  };

  const handleClosePopup = () => {
    setSelectedQuery(null);
  };

  // Pagination Logic
  const totalPages = Math.ceil(queries.length / queriesPerPage);
  const startIndex = (currentPage - 1) * queriesPerPage;
  const currentQueries = queries.slice(startIndex, startIndex + queriesPerPage);

  const goToNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const goToPreviousPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  return (
    <section className="ftco-section">
      <div className="container">
        <div className="head">
          <h2>Admin Query</h2>
        </div>
        <form>
          <table className="order-table mb-4" cellPadding={1} cellSpacing={1}>
            <thead>
              <tr>
                <th>Query ID</th>
                <th>Customer Name</th>
                <th>Description</th>
                <th>Email ID</th>
                <th>Contact Number</th>
                <th>Answer</th>
              </tr>
            </thead>
            <tbody>
              {currentQueries.map((query) => (
                <tr key={query._id} onClick={() => handleViewQuery(query)} style={{ cursor: 'pointer' }}>
                  <td>{query._id}</td>
                  <td>{`${query.firstName} ${query.lastName}`}</td>
                  <td>{query.query}</td>
                  <td>{query.email}</td>
                  <td>{query.contactNumber}</td>
                  <td>
                    <select
                      className="form-select"
                      value={answers[query._id] || "No"}
                      onChange={(e) => handleAnswerChange(query._id, e.target.value)}
                    >
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </form>

        {/* Pagination Controls */}
        <div className="pagination">
          <button onClick={goToPreviousPage} disabled={currentPage === 1}>
            Previous
          </button>
          <span>Page {currentPage} of {totalPages}</span>
          <button onClick={goToNextPage} disabled={currentPage === totalPages}>
            Next
          </button>
        </div>

        {selectedQuery && (
          <div className="popup-overlay" onClick={handleClosePopup}>
            <div className="popup-content" onClick={(e) => e.stopPropagation()}>
              <h3>Query Details</h3>
              <p><strong>Query ID:</strong> {selectedQuery._id}</p>
              <p><strong>Customer Name:</strong> {`${selectedQuery.firstName} ${selectedQuery.lastName}`}</p>
              <p><strong>Email ID:</strong> {selectedQuery.email}</p>
              <p><strong>Contact Number:</strong> {selectedQuery.contactNumber}</p>
              <p><strong>Query:</strong> {selectedQuery.query}</p>
              <button onClick={handleClosePopup}>Close</button>
            </div>
          </div>
        )}
      </div>
    </section>
  );
}

export default AdminQuery;
