import React from 'react'
import ProjectSection from './ProjectSection';
import Testimonials from './Testimonials';
import RUPEEFUNDImage from '../images/image_rupeeFund.jpg';

function FundsSavingAccount() {
  return (
    <>
      <section className="ftco-section" id="services-section">
        <div className="container">
          <h1>Convert Resident a/c Rupee funds to NRE</h1>
          <p>Prevailing Foreign Exchange Management Act (FEMA) regulations mandate that Non-Resident Indians (NRIs)/Persons of Indian Origin (PIOs)/Overseas Citizens of India (OCIs), to close all their resident savings/current accounts or convert them to NRO account.</p>
          <p>In case you fail to convert your resident savings account to an NRO account there are penalties involved, including:</p>
          <ul>
            <li>A fine of up to three times the amount in your bank account; or</li>
            <li>A fine of ₹2 lakh if the amount is not quantifiable.</li>
            <li>Additionally, you may be subject to a penalty of ₹5,000 per day from the first day of non-compliance till the penalty is duly paid.</li>
          </ul>
          <img src={RUPEEFUNDImage} alt="NRI Cash Gifts" />
          <h4 className="mt-4">How we can help?</h4>
          <ul>
            <li>Supply CA Certificate Form 15CA , 15CB (we will provide)</li>
            <li>to move funds to NRO</li>
            <li>tax filing</li>
            <li>to move funds to NRE</li>
          </ul>
        </div>
      </section>
      <Testimonials />
    </>
  )
}

export default FundsSavingAccount