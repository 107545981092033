import React from 'react'
import ProjectSection from './ProjectSection';
import Testimonials from './Testimonials';
import CAPITALGAINSImage from '../images/image_capitalGains.jpg';

function CapitalGains() {
  return (
    <>
      <section className="ftco-section" id="services-section">
        <div className="container">
          <h1>Capital Gains</h1>
          <p>The Indian tax system delineates specific provisions for residents and non-residents, which can have a profound impact on the taxation of capital gains.</p>
          <p>Capital gains are profits that arise from the sale of a capital asset, such as property, stocks, or bonds, when the sale price exceeds the purchase price.</p>
          <p>A NRI could inherit the property or got the property as a gift or could have purchased the property on his own.</p>
          <p>The Indian Income Tax Act classifies capital assets and prescribes distinct tax treatments for gains, categorizing them as either short-term or long-term based on the asset's holding period. Short-term capital gains (STCG) are applicable to assets held for a brief duration, typically less than 36 months for real estate and 12 months for securities. Conversely, long-term capital gains (LTCG) are levied on assets retained beyond these thresholds.</p>
          <p>The tax rates for capital gains for NRIs are contingent on the asset type and the duration of holding. For instance, LTCG on equity shares and equity-oriented mutual funds, if held for over 12 months, are taxed at 10% without indexation, with the stipulation that the gains exceed INR 1 lakh. STCG on these securities are taxed at 15%. In the case of debt-oriented mutual funds, LTCG are taxed at 20% with indexation after a holding period of more than 36 months, while STCG are taxed according to the individual's income tax slab for shorter durations.</p>
          <p>For real estate transactions, the holding period for a property to qualify as long-term is set at more than 24 months. LTCG from property sales are taxed at 20% with the benefit of indexation, whereas STCG are taxed as per the individual's income tax slab rates.</p>
          <img src={CAPITALGAINSImage} alt="NRI Cash Gifts" />
          <h4 className="mt-4">How we can help?</h4>
          <ul>
            <li>Establishing the real capital gains</li>
            <li>Supply FORM CA Certificate Form 15CA , 15CB (we will provide)</li>
            <li>to move funds to NRE</li>
            <li>Advising and helping with investing in tax savings bonds under section 54EC (No tax to be paid on the amount and funds repatriable after 5 years)</li>
            <li>Help with section54F with capital gains from non-residential assets</li>
          </ul>
        </div>
      </section>
      <Testimonials />
    </>
  )
}

export default CapitalGains