import React, { createContext, useState, useEffect } from 'react';
import {jwtDecode} from 'jwt-decode';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [user, setUser] = useState(null);

  useEffect(() => {
    if (checkAuth()) {
      const token = sessionStorage.getItem('token');
      setUser(getUserFromToken(token)); // Retrieve actual user info from the token
    } else {
      logout();
    }
  }, []);

  const login = (user, token) => {
    sessionStorage.setItem('token', token);
    setLoggedIn(true);
    setUser(user);
  };

  const logout = () => {
    sessionStorage.removeItem('token');
    setLoggedIn(false);
    setUser(null);
  };

  const checkTokenExpiry = (token) => {
    try {
      const decoded = jwtDecode(token);
      const currentTime = Date.now() / 1000;
      return decoded.exp > currentTime;
    } catch (e) {
      return false;
    }
  };

  const getUserFromToken = (token) => {
    try {
      const decoded = jwtDecode(token);
      return { username: decoded.username }; // Adjust based on your token payload
    } catch (e) {
      return null;
    }
  };

  const checkAuth = () => {
    const token = sessionStorage.getItem('token');
    if (token && checkTokenExpiry(token)) {
      setLoggedIn(true);
      return true;
    } else {
      setLoggedIn(false);
      return false;
    }
  };

  return (
    <AuthContext.Provider value={{ loggedIn, user, login, logout, checkAuth }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
