import React from 'react'
import ProjectSection from './ProjectSection';
import Testimonials from './Testimonials';
import DSCImage from '../images/image_dsc.jpg';

function DigitalSignatureCertificate() {
  return (
    <>
      <section className="ftco-section" id="services-section">
        <div className="container">
          <h1>Digital Signature Certificate</h1>
          <p>
          Digital signature for NRIs (Non-Resident Indians), scattered across the globe is a necessity to authenticate documents and transactions remotely. DSC provides NRIs with a legally recognized electronic signature, eliminating the need for physical presence and paperwork hassles.
          </p>
          <p>Whether signing contracts, filing taxes, or managing financial affairs, DSC for NRIs provides seamless transactions, legal formalities across borders, and convenience in managing their affairs from anywhere in the world</p>
          <p><strong>Why Us?</strong></p>
          <p>There are lot of online services available for DSC and information available is very clumsy and overwhelming. We want to take the worry out of you and just do the job for you. You not only have an online experience but our team will talk to you.</p>
          <img src={DSCImage} alt="Digital Signature Certificate" />
          <h4 className="mt-4">STRESS FREE AND TRUSTED PARTENER</h4>
          <p>
            <strong>How we can help?</strong>
          </p>
          <p>
          We can advise on the best option and our team will support you all the way to have a stress free and trustful experience in obtaining the DSC.

          </p>
          <p>
            <strong>Eligibility Criteria?</strong>
          </p>
          <p>
          A NRI or a foreign citizen is eligible to obtain DSC by supplying, Proof of Identity- A valid passport orany other government-issued identification, Proof of Address: Utility bills, bank statements, or any other documents confirming residential address, Valid Visa or OCI Card .

          </p>
        </div>
      </section>
      <Testimonials />
    </>
  )
}

export default DigitalSignatureCertificate