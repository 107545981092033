import React from 'react'
import Service1 from '../images/service1.jpg';
import Service2 from '../images/service2.jpg';
import Service3 from '../images/service3.jpg';
import Service4 from '../images/service4.jpg';
import Service5 from '../images/service5.jpg';
import Service6 from '../images/service6.jpg';
import Testimonials from './Testimonials';
import ProjectSection from './ProjectSection';
import { NavLink } from 'react-router-dom';

function Home() {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  return (
    <>
      <section>
        <div className="home-slider owl-carousel">
          <div className="slider-item">
            <div className="container-fluid px-md-0">
              <div className="row d-md-flex no-gutters slider-text align-items-end justify-content-end" data-scrollax-parent="true">
                <div className="one-third order-md-last">
                  <div className="overlay"></div>
                  <div className="overlay-1"></div>
                </div>
                <div className="one-forth d-flex align-items-center " data-scrollax="properties: { translateY: '70%' }">
                  <div className="text">
                    <h1>NRI Tax Solutions</h1>
                    <h3>We help NRIs make their funds available to them when and where they need it and be fully tax compliant.</h3>
                    <ul>
                      <li>Have you received monetary gifts from relatives/friends?</li>
                      <li>Have you made a sale of your property or inherited property in India?</li>
                      <li>Have you got funds stuck in your resident saving account?</li>
                      <li>Do you need to do a tax filing in India?</li>
                    </ul>
                    <p><NavLink to="/contact-us" className="btn btn-white inline-block px-3 py-2" activeClassName="active">Contact Us</NavLink>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="ftco-section" id="services-section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12 heading-section text-center  mb-5">
              <h2 className="mb-4">If any of the following services requied then
                <br></br>NRI Tax Solutions UK certainly can assist you.</h2>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6 col-lg-4">
              <div className="media block-6 services d-block bg-white rounded-lg shadow ">
                <NavLink className="dropdown-item" to="/pan-card-services" onClick={scrollToTop}>
                  <div className="d-flex align-items-center justify-content-center">
                    <img src={Service1} alt="Pan Card Service" />
                  </div>
                  <div className="media-body text-center">
                    <h3 className="heading mb-3">PAN Card Services</h3>
                  </div>
                </NavLink>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="media block-6 services d-block bg-white rounded-lg shadow ">
                <NavLink className="dropdown-item" to="/digital-signature-certificate" onClick={scrollToTop}>
                  <div className="d-flex align-items-center justify-content-center">
                    <img src={Service2} alt="Digital Signature Certificate" />
                  </div>
                  <div className="media-body text-center">
                    <h3 className="heading mb-3">Digital Signature Certificate</h3>
                  </div>
                </NavLink>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="media block-6 services d-block bg-white rounded-lg shadow ">
                <NavLink className="dropdown-item" to="/tax-filing" onClick={scrollToTop}>
                  <div className="d-flex align-items-center justify-content-center">
                    <img src={Service3} alt="Tax Filing" />
                  </div>
                  <div className="media-body text-center">
                    <h3 className="heading mb-3">Tax Filing</h3>
                  </div>
                </NavLink>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="media block-6 services d-block bg-white rounded-lg shadow ">
                <NavLink className="dropdown-item" to="/cash-gifts" onClick={scrollToTop}>
                  <div className="d-flex align-items-center justify-content-center">
                    <img src={Service4} alt="Cash Gifts" />
                  </div>
                  <div className="media-body text-center">
                    <h3 className="heading mb-3">Cash Gifts</h3>
                  </div>
                </NavLink>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="media block-6 services d-block bg-white rounded-lg shadow ">
                <NavLink className="dropdown-item" to="/capital-gains" onClick={scrollToTop}>
                  <div className="d-flex align-items-center justify-content-center">
                    <img src={Service5} alt="Capital Gains" />
                  </div>
                  <div className="media-body text-center">
                    <h3 className="heading mb-3">Capital Gains</h3>
                  </div>
                </NavLink>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="media block-6 services d-block bg-white rounded-lg shadow ">
                <NavLink className="dropdown-item" to="/funds-saving-account" onClick={scrollToTop}>
                  <div className="d-flex align-items-center justify-content-center">
                    <img src={Service6} alt="Funds in Resident Savings Account" />
                  </div>
                  <div className="media-body text-center">
                    <h3 className="heading mb-3">Funds in Resident<br /> Savings Account</h3>
                  </div>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </section>
      <h4 className='text-center'>
        For our standard fees call or contact us. Fees will vary depending upon individual circumstances
      </h4>
      <Testimonials />
    </>
  )
}

export default Home