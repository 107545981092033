import React, { useState } from "react";
import { useForm } from "react-hook-form";
import api from './api';

function ContactUs() {
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const onSubmit = async (data) => {
    // Clear previous messages
    setSuccessMessage('');
    setErrorMessage('');

    try {
      const response = await api.post(`/query/send-query`, data);
      setSuccessMessage('Query submitted successfully!');
      reset(); // Reset form fields
    } catch (error) {
      console.error('Error submitting the query:', error);
      setErrorMessage('Failed to submit the query.');
    }
  };

  return (
    <section className="ftco-section contact-section ftco-no-pb" id="contact-section">
      <div className="container">
        <div className="row justify-content-center mb-5 pb-3">
          <div className="col-md-7 heading-section text-center">
            <h3>Contact us</h3>
          </div>
        </div>
        <div className="row block-9">
          <div className="col-md-8">
            {successMessage && <p className="text-success">{successMessage}</p>}
            {errorMessage && <p className="text-danger">{errorMessage}</p>}
            <form onSubmit={handleSubmit(onSubmit)} className="bg-light p-4 p-md-5 contact-form">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="First Name"
                      {...register("firstName", { required: "First Name is required" })}
                    />
                    {errors.firstName && <p className="text-danger">{errors.firstName.message}</p>}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Last Name"
                      {...register("lastName", { required: "Last Name is required" })}
                    />
                    {errors.lastName && <p className="text-danger">{errors.lastName.message}</p>}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Email Id"
                      {...register("email", {
                        required: "Email is required",
                        pattern: {
                          value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
                          message: "Invalid email address"
                        }
                      })}
                    />
                    {errors.email && <p className="text-danger">{errors.email.message}</p>}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Phone Number"
                      maxLength={10}
                      {...register("contactNumber", { required: "Phone Number is required" })}
                    />
                    {errors.contactNumber && <p className="text-danger">{errors.contactNumber.message}</p>}
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <textarea
                      name=""
                      id=""
                      cols="30"
                      rows="7"
                      className="form-control"
                      placeholder="Query"
                      {...register("query", { required: "Query is required" })}
                    ></textarea>
                    {errors.query && <p className="text-danger">{errors.query.message}</p>}
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <input
                      type="submit"
                      value="Submit"
                      className="btn btn-primary py-3 px-5"
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="col-md-4 d-flex pl-md-5">
            <div className="row">
              <div className="dbox w-100 d-flex">
                <div className="icon d-flex align-items-center justify-content-center">
                  <span className="fa fa-map-marker"></span>
                </div>
                <div className="text">
                  <p>
                    <span>Address:</span> Bristol, United Kingdom
                  </p>
                </div>
              </div>
              <div className="dbox w-100 d-flex">
                <div className="icon d-flex align-items-center justify-content-center">
                  <span className="fa fa-phone"></span>
                </div>
                <div className="text">
                  <p>
                    <span>Phone:</span>
                    <a href="tel://+447546883119">+447546883119</a>
                  </p>
                </div>
              </div>
              <div className="dbox w-100 d-flex">
                <div className="icon d-flex align-items-center justify-content-center">
                  <span className="fa fa-paper-plane"></span>
                </div>
                <div className="text">
                  <p>
                    <span>Email:</span>
                    <a href="mailto:operations@nritaxsolutions.com">operations@nritaxsolutions.com</a>
                  </p>
                </div>
              </div>
              <div className="dbox w-100 d-flex">
                <div className="icon d-flex align-items-center justify-content-center">
                  <span className="fa fa-globe"></span>
                </div>
                <div className="text">
                  <p>
                    <span>Website</span> <a href="https://www.nritaxsolutions.com" target="_blank">nritaxsolutions.com</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ContactUs;
