import React, { useState, useEffect } from "react";
import { useParams, Link } from 'react-router-dom'; // Import Link
import api from './api';

function UpdateOrder() {
  const { orderId } = useParams();
  const [orderData, setOrderData] = useState({
    orderId: '',
    customerName: '',
    queryReference: '',
    customerId: '',
    customerAddress: '',
    customerContactNo: '',
    customerEmail: '',
    customerCity: '',
    customerPostalCode: '',
    description: '',
    status: 'Pending',
    totalFee: 0,
    feesPaid: 0,
    feesDue: 0, // Initially set to 0
    documentsUploaded: 'No',
    invoiceReference: '',
    paymentStatus: '',
    advanceFee: '',
    attachedDocuments: []
  });

  useEffect(() => {
    const fetchOrderData = async () => {
      try {
        const token = sessionStorage.getItem("token"); // Get the token from sessionStorage
        const response = await api.get(`/admin/getOrder/${orderId}`, {
          headers: {
            Authorization: `Bearer ${token}`, // Attach the token to the request
          },
        });

        const data = response.data;
        // Calculate feesDue when data is fetched
        const totalFee = parseInt(data.totalFee, 10) || 0;
        const feesPaid = parseInt(data.feesPaid, 10) || 0;
        const feesDue = totalFee - feesPaid; // Directly subtract and avoid decimals

        setOrderData({
          ...data,
          feesDue, // Update feesDue with calculated value
        });
      } catch (error) {
        console.error("Error fetching order data:", error);
      }
    };

    fetchOrderData();
  }, [orderId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const newValue = name === 'totalFee' || name === 'feesPaid' ? parseInt(value, 10) || 0 : value;

    setOrderData(prevState => {
      const updatedState = { ...prevState, [name]: newValue };

      // Calculate feesDue if totalFee or feesPaid changes
      if (name === 'totalFee' || name === 'feesPaid') {
        const totalFee = parseInt(updatedState.totalFee, 10) || 0;
        const feesPaid = parseInt(updatedState.feesPaid, 10) || 0;
        updatedState.feesDue = totalFee - feesPaid; // Directly subtract and avoid decimals
      }

      return updatedState;
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = sessionStorage.getItem("token"); // Get the token from sessionStorage
      const response = await api.put(`/admin/update-form/${orderId}`, orderData, {
        headers: {
          Authorization: `Bearer ${token}`, // Attach the token to the request
        },
      });
      console.log("Order updated successfully:", response.data);
    } catch (error) {
      console.error("Error updating order:", error);
    }
  };

  return (
    <section className="ftco-section">
      <div className="container">
        <div className="creater-order table-1">
          <h2>Update Order</h2>
          <form onSubmit={handleSubmit}>
            {orderData?.customerData && (
              <table className="order-table mb-4" cellPadding={1} cellSpacing={1}>
                <tbody>
                  <tr>
                    <td>Order ID</td>
                    <td>{orderData.orderId}</td>
                  </tr>
                  <tr>
                    <td>Customer Name</td>
                    <td><input type="text" name="customerName" value={orderData.customerData.firstName || orderData.customerData.name} onChange={handleChange} /></td>
                  </tr>
                  <tr>
                    <td>Query Reference</td>
                    <td><input type="text" name="queryReference" value={orderData.queryReference} onChange={handleChange} /></td>
                  </tr>
                  <tr>
                    <td>Customer ID</td>
                    <td>{orderData.customerData._id}</td>
                  </tr>
                  <tr>
                    <td>Customer Address</td>
                    <td><input type="text" name="customerAddress" value={orderData.customerAddress || orderData.customerData.address} onChange={handleChange} /></td>
                  </tr>
                  <tr>
                    <td>Customer Contact Number</td>
                    <td><input type="text" name="customerContactNo" value={orderData.customerData.contactNo} onChange={handleChange} /></td>
                  </tr>
                  <tr>
                    <td>Customer Email ID</td>
                    <td><input type="text" name="customerEmail" value={orderData.customerData.email} onChange={handleChange} /></td>
                  </tr>
                  <tr>
                    <td>Customer City</td>
                    <td><input type="text" name="customerCity" value={orderData.customerData.city} onChange={handleChange} /></td>
                  </tr>
                  <tr>
                    <td>Customer Postal Code</td>
                    <td><input type="text" name="customerPostalCode" value={orderData.customerData.postalCode} onChange={handleChange} /></td>
                  </tr>
                  <tr>
                    <td>Description</td>
                    <td><input type="text" name="description" value={orderData.service} onChange={handleChange} /></td>
                  </tr>
                  <tr>
                    <td>Status</td>
                    <td>
                      <select name="status" value={orderData.status} onChange={handleChange}>
                        <option value="Pending">Pending</option>
                        <option value="In Progress">In Progress</option>
                        <option value="Complete">Complete</option>
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <td>Advance Fee</td>
                    <td><input type="text" name="advanceFee" value={orderData.advanceFee} onChange={handleChange} /></td>
                  </tr>
                  <tr>
                    <td>Total Fee</td>
                    <td><input type="text" name="totalFee" value={orderData.totalFee} onChange={handleChange} /></td>
                  </tr>
                  <tr>
                    <td>Fees Paid</td>
                    <td><input type="text" name="feesPaid" value={orderData.feesPaid} onChange={handleChange} /></td>
                  </tr>
                  <tr>
                    <td>Fees Due</td>
                    <td><input type="text" name="feesDue" value={orderData.feesDue} readOnly /></td>
                  </tr>
                  <tr>
                    <td>Payment Status</td>
                    <td><input type="text" name="paymentStatus" value={orderData.paymentStatus} onChange={handleChange} /></td>
                  </tr>
                  <tr>
                    <td>Documents Uploaded</td>
                    <td>
                      <select name="documentsUploaded" value={orderData.documentsUploaded} onChange={handleChange}>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <td>Invoice Reference</td>
                    <td><input type="text" name="invoiceReference" value={orderData.invoiceReference} onChange={handleChange} /></td>
                  </tr>
                  <tr>
                    <td align="top" valign="top">Documents Attached</td>
                    {/* Add logic for displaying documents if needed */}
                  </tr>
                </tbody>
              </table>
            )}
            <section className="">
              <button type="submit" className="btn btn-success py-3 px-4 mr-4">Update</button>
              <Link to="/dashboard" className="btn btn-primary py-3 px-4">
                Go to Dashboard
              </Link>
            </section>

          </form>


        </div>
      </div>
    </section>
  );
}

export default UpdateOrder;
