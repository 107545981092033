import React,{useState} from 'react';
import { useForm,watch } from 'react-hook-form';
import api from './api';
import { useNavigate } from 'react-router-dom';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
function Register({ onLogin }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm();

  const password = watch('password'); // Watch the password field to validate confirmPassword
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const togglePasswordVisibility = () => setShowPassword(!showPassword);
  const toggleConfirmPasswordVisibility = () => setShowConfirmPassword(!showConfirmPassword);
  const onSubmit = async (data) => {
    try {
      console.log('bhasakr')
      const { confirmPassword, ...registerData } = data; // Exclude confirmPassword from the data

      console.log(data, "dsfjs")
      const registerResponse = await api.post(`/auth/register`, registerData);
      if (registerResponse?.data?.statusCode) {
        // Perform login after successful registration
        const loginResponse = await api.post(`/auth/login`, {
          email: registerData.email,
          password: registerData.password,
        });
        if (loginResponse?.data?.statusCode) {
          onLogin(loginResponse?.data?.user, loginResponse?.data?.token)
          navigate('/dashboard');
        } else {
          alert('Login failed.');
        }
      } else {
        alert('Registration failed.');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };


  return (
    <section className="ftco-section" id="services-section">
      <div className="container">
        <div className="row">
          <div className="col-md-12 heading-section mb-5">
            <h2 className="mb-4">Create An Account</h2>
            <form
              className="needs-validation bg-light p-4 p-md-5"
              onSubmit={handleSubmit(onSubmit)}
              noValidate
            >
              <div className="form-row form-group">
                {/* Title Field */}
                <div className="col-md-6 mb-3">
                  <label htmlFor="title">Title</label>
                  <select
                    className="form-control"
                    id="title"
                  >
                    <option value="">Select Title</option>
                    <option value="Mr.">Mr.</option>
                    <option value="Mrs.">Mrs.</option>
                    <option value="Ms.">Ms.</option>
                    <option value="Dr.">Dr.</option>
                  </select>
                </div>

                {/* First Name Field */}
                <div className="col-md-6 mb-3 position-relative">
                  <label htmlFor="firstName">First name</label>
                  <sup className='text-danger'>*</sup>
                  {errors.firstName && (
                    <div className="invalid-feedback-error text-danger position-absolute" style={{ top: '0', right: '10px' }}>
                      {errors.firstName.message}
                    </div>
                  )}
                  <input
                    type="text"
                    className="form-control"
                    id="firstName"
                    placeholder="First name"
                    {...register('firstName', { required: 'First name is required' })}
                  />
                  
                </div>

                {/* Last Name Field */}
                <div className="col-md-6 mb-3">
                  <label htmlFor="lastName">Last name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="lastName"
                    placeholder="Last name"
                  />
                </div>

                {/* Email Field */}
                <div className="col-md-6 mb-3 position-relative">
                  <label htmlFor="email">Email Id</label>
                  <sup className='text-danger'>*</sup>
                  {errors.email && (
                    <div className="invalid-feedback-error text-danger position-absolute" style={{ top: '0', right: '10px' }}>{errors.email.message}</div>
                  )}
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    placeholder="info@mail.com"
                    {...register('email', {
                      required: 'Email is required',
                      pattern: {
                        value: /^\S+@\S+$/i,
                        message: 'Please provide a valid Email Id.',
                      },
                    })}
                  />
                  
                </div>

                {/* Password Field */}
                <div className="col-md-6 mb-3 position-relative">
                  <label htmlFor="password">Password</label>
                  <sup className='text-danger'>*</sup>
                  {errors.password && (
                    <div className="invalid-feedback-error text-danger position-absolute" style={{ top: '0', right: '10px' }}>{errors.password.message}</div>
                  )}
                  <input
                    type={showPassword ? 'text' : 'password'}
                    className="form-control"
                    id="password"
                    placeholder="**********"
                    {...register('password', { required: 'Password is required' })}
                  />
                 
                  <span
                    className="password-toggle-icon"
                    onClick={togglePasswordVisibility}
                    style={{ position: 'absolute', top: '46px', right: '20px', cursor: 'pointer' }}
                  >
                    {showPassword ? <FaEyeSlash /> : <FaEye />}
                  </span>
                </div>

                {/* Confirm Password Field */}
                <div className="col-md-6 mb-3 position-relative">
                  <label htmlFor="confirmPassword">Confirm Password</label>
                  <sup className='text-danger'>*</sup>
                  {errors.confirmPassword && (
                    <div className="invalid-feedback-error text-danger position-absolute" style={{ top: '0', right: '10px' }}>{errors.confirmPassword.message}</div>
                  )}
                  <input
                    type={showConfirmPassword ? 'text' : 'password'}
                    className="form-control"
                    id="confirmPassword"
                    placeholder="**********"
                    {...register('confirmPassword', {
                      required: 'Please confirm your password',
                      validate: (value) =>
                        value === password || 'Passwords do not match',
                    })}
                  />
                  
                  <span
                    className="password-toggle-icon"
                    onClick={toggleConfirmPasswordVisibility}
                    style={{ position: 'absolute', top: '46px', right: '20px', cursor: 'pointer' }}
                  >
                    {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                  </span>
                </div>

                {/* House Number Field */}
                <div className="col-md-6 mb-3">
                  <label htmlFor="houseNumber">House Number</label>
                  <input
                    type="text"
                    className="form-control"
                    id="address"
                    placeholder="Address"
                    {...register('address')}
                  />
                </div>

                {/* Street Name Field */}
                <div className="col-md-6 mb-3">
                  <label htmlFor="streetName">Street Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="streetName"
                    placeholder="Street Name"
                  />
                </div>

                {/* City Field */}
                <div className="col-md-6 mb-3">
                  <label htmlFor="city">Place/Town</label>
                  <input
                    type="text"
                    className="form-control"
                    id="place"
                    placeholder="Place/Town"
                  />
                </div>

                {/* State Field */}
                <div className="col-md-6 mb-3">
                  <label htmlFor="state">State/City</label>
                  <input
                    type="text"
                    className="form-control"
                    id="state"
                    placeholder="State"
                  />
                </div>

                {/* Postal Code Field */}
                <div className="col-md-6 mb-3">
                  <label htmlFor="postalCode">Postal Code</label>
                  <input
                    type="text"
                    className="form-control"
                    id="postalCode"
                    placeholder="110001"
                  />
                </div>

                {/* Phone Number Field */}
                <div className="col-md-6 mb-3">
                  <label htmlFor="contactNo">Phone Number</label>
                  <input
                    type="text"
                    className="form-control"
                    id="contactNo"
                    placeholder="Phone Number"
                    {...register('contactNo', {
                      pattern: {
                        value: /^[0-9]{10}$/,
                        message: 'Please enter a valid Phone Number.',
                      },
                    })}
                  />
                  {errors.contactNo && (
                    <div className="invalid-feedback-error text-danger">{errors.contactNo.message}</div>
                  )}
                </div>
              </div>
              <button className="btn btn-primary" type="submit">
                Create Account
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Register;
